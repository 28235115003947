<template>
  <div class="container">
    <div class="card pj-card">
      <div class="card-body">
        <h1 class="display-4 pj-display-4">О нас 📝</h1>
        <p class="card-text lead">
          📅 Радуем вас промокодами с декабря <b>2018 года!</b>
        </p>
        <p class="card-text lead">
          🔝 ТОП-1 Google по соответствующим запросам!
        </p>
        <p class="card-text lead">
          🎉 Больше 100.000 визитов в год!
        </p>
      </div>
      <div class="card-footer text-center">
        👨‍💻 Разработчик:
        <a href="https://disonds.com" target="_blank" rel="author noopener"
          >DiSonDS</a
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pj-display-4 {
  font-size: 42px;
}
@media (prefers-color-scheme: dark) {
  .pj-card {
    background-color: #232324;
    color: white;
  }
  a {
    color: #809fff;
  }
}
</style>
